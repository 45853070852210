import { parse, unparse } from 'papaparse'

export function genCSVDownloadLink<T extends Record<string, any>>(data: T[], attributes: (keyof T)[], filename: string) {
  const csvString = convertToCSV(data, attributes)
  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' })
  const link = document.createElement('a')
  const url = URL.createObjectURL(blob)
  link.href = url
  link.setAttribute('download', filename)
  document.body.append(link)
  link.click()
  link.remove()
  URL.revokeObjectURL(url)
}
export function convertToCSV<T extends Record<string, any>>(data: T[], attributes: (keyof T)[]) {
  const csvData = data.map(item => _pick(item, attributes))
  return unparse(csvData)
}

function readFileAsText(file: File | Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result as string)
    }
    reader.onerror = () => {
      reject(new Error('Error reading file'))
    }
    reader.readAsText(file)
  })
}

export function checkInputFileCsv(e: Event) {
  const input = e.target as HTMLInputElement
  if (!input.files?.length)
    throw new Error('No file selected')

  const file = input.files[0]
  if (file.type !== 'text/csv')
    throw new Error('File is not a CSV')

  return file
}

export async function parseInputFileCsv<T>(file: File | Blob): Promise<T[]> {
  const csvData = await readFileAsText(file)
  return await parseCsvData<T>(csvData)
}

export async function parseCsvData<T>(data: string): Promise<T[]> {
  return new Promise((resolve, reject) => {
    parse<T>(data, {
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        resolve(result.data)
      },
      error: (error: unknown) => {
        if (error instanceof Error)
          reject(new Error(`Error parsing CSV: ${error.message}`))
      },
    })
  })
}

const stringTransformations: iCsvParserTransformations = {
  string: (input: string) => input,
  number: (input: string) => Number(input),
  date: (input: string) => new Date(input),
  boolean: (input: string) => !!input,
}

export function transformCsvDataset<T extends object>(data: Array<unknown>, dict: iCsvParserDefinition): T[] {
  return data.map((item, index) => {
    if (typeof item !== 'object' || item === null)
      throw new Error(`Array item at index ${index} is not an object`)

    const itemKeys = Object.keys(item)
    const missingKeys = Object.keys(dict).filter(key => !itemKeys.includes(key))
    if (missingKeys.length > 0)
      throw new Error(`Array item at index ${index} is missing required keys: ${missingKeys.join(', ')}`)

    const obj = {} as T
    for (const [key, { name, type }] of Object.entries(dict)) {
      const value = (item as { [key: string]: any })[key]
      // @ts-expect-error we are making sure before
      obj[name] = stringTransformations[type](value)
    }
    return obj
  })
}
