import { DateTime } from 'luxon'

const udVisualSettings: iUdVisualSettings = {
  borderTreshold: 0.25,
  left: 'ud_adp_rank',
  right: undefined,
  center: 'roster_per',
  bordercolor: 'id_pos',
  visual: 'board',
}
const udAdpVisualSettings: iUdAdpVisualSettings = {
  left: 'ud_adp_rank',
  right: undefined,
  board: 'hex',
  center: 'ud_adp_posrank',
  bordercolor: 'id_pos',
}

const rostershipRanges: Array<iPlayerRosterRange> = [
  { title: 'The\'re Family', range: [1, 0.30], players: [], color: 'text-emerald-500', level: 'high' },
  { title: 'Roster Royalty', range: [0.30, 0.25], players: [], color: 'text-emerald-500', level: 'high' },
  { title: 'Massive Fan', range: [0.25, 0.2], players: [], color: 'text-lime-500', level: 'high' },
  { title: 'Frequent Flyers', range: [0.20, 0.15], players: [], color: 'text-green-500', level: 'med' },
  { title: 'Occasional Picks', range: [0.15, 0.10], players: [], color: 'text-amber-500', level: 'low' },
  { title: 'Uncommon Picks', range: [0.10, 0.05], players: [], color: 'text-red-500', level: 'low' },
  { title: 'Rare Picks', range: [0.05, 0], players: [], color: 'text-red-500', level: 'low' },
]

function genDefaultUdTypesObj<T>(value: T) {
  const obj = {} as Record<iUdTypes, T>
  for (const type of udTypes) {
    obj[type] = value
  }
  return obj
}

const defintionUdTab: iModalTabDefinition[] = [
  { slot: 'adp', label: 'Adp', icon: 'i-mdi-chart-bell-curve', description: 'Adp Settings' },
  { slot: 'rostership', label: 'Rostership', icon: 'i-material-symbols-account-tree-outline', description: 'Rostership Settings' },
]

export const useUdStore = defineStore({
  id: 'UdStore',
  state: (): iUdStoreState => ({
    activeType: 'Season',
    adpData: genDefaultUdTypesObj([]),
    rostershipData: genDefaultUdTypesObj([]),
    rostershipPickData: genDefaultUdTypesObj([]),
    ids: genDefaultUdTypesObj(new Map()),
    refreshDates: { adpData: genDefaultUdTypesObj(undefined), ids: genDefaultUdTypesObj(undefined) },
    showModal: false,
    draftModal: false,
    insightModal: false,
    modalTab: 0,
    draftId: undefined,
    isLoading: false,
    visualSettings: udVisualSettings,
    adpVisualSettings: udAdpVisualSettings,
  }),
  persist: {
    pick: ['activeType', 'ids', 'refreshDates', 'rostershipData', 'rostershipPickData', 'adpData', 'adpVisualSettings', 'visualSettings'],
  },
  getters: {
    getUdAdp(state): Array<iPlayerUd> {
      return state.adpData[state.activeType]
    },
    getUdRoster(state): Array<iPlayerUdRosterAdp> {
      return state.rostershipData[state.activeType]
    },
    getUdPickData(state): Array<iUdPick> {
      return state.rostershipPickData[state.activeType]
    },
    getModalTabConfig(): iModalTabDefinition[] {
      return defintionUdTab
    },
    getUdIds(state): Map<string, { id_ud: string, id_sleeper: string }> {
      return state.ids[state.activeType]
    },
    getUdTypeId(state): number {
      return udTypesIds[state.activeType]
    },
    getRostershipRanges(): Array<iPlayerRosterRange> {
      return rostershipRanges
    },
  },
  actions: {
    getRosteredPlayersmap(players: Array<string>) {
      return new Map(this.getUdRoster.filter(player => players.includes(player.id_ud)).map(player => [player.id_ud, player]))
    },
    openModal(tab?: string): void {
      const tabIndex = tab ? defintionUdTab.findIndex(def => def.slot === tab) : -1
      this.$patch((state) => {
        if (tabIndex !== -1)
          state.modalTab = tabIndex
        state.showModal = true
      })
    },
    setType(id: number) {
      const entry = Object.entries(udTypesIds).find(([_, value]) => value === id)
      if (entry && entry[0])
        this.$patch({ activeType: entry[0] as iUdTypes })
    },
    async fetchAdpData() {
      this.$patch({ isLoading: true })
      try {
        const adpData = await fetchUnderdogAdp(this.getUdTypeId)
        this.$patch((state) => {
          state.adpData[state.activeType] = adpData.slice(0, 264)
          state.refreshDates.adpData[state.activeType] = DateTime.now().toMillis()
        })
      }
      finally {
        this.$patch({ isLoading: false })
      }
    },
    async checkAdpData(activeType?: iUdTypes) {
      if (activeType)
        this.$patch({ activeType })
      if (!this.hasAdpData())
        await this.fetchAdpData()
    },
    hasAdpData() {
      const hasAdpData = this.getUdAdp.length > 0
      const lastDate = this.refreshDates.adpData[this.activeType]
      const lastDateIsValid = lastDate ? DateTime.fromMillis(lastDate).diffNow().as('hours') < 6 : false
      return hasAdpData && lastDateIsValid
    },
    async addUdPlayerMetrics(
      dataset: iPlayer[],
      metrics: (keyof iPlayerUd)[] = ['id_ud', 'ud_adp', 'ud_projection_ppg', 'ud_projection'],
      origin: iPlayerStringOrigin = 'id_sleeper',
    ) {
      await this.checkAdpData()
      patchUdPlayerMetrics(dataset, this.getUdAdp, metrics, origin)
    },
    async fetchIdsData() {
      this.$patch({ isLoading: true })
      try {
        const idsData = await fetchUnderdogIds(this.getUdTypeId)
        this.$patch((state) => {
          state.ids[state.activeType] = new Map(idsData.map(id => [id.id_udc, { id_ud: id.id_ud, id_sleeper: id.id_sleeper }]))
          state.refreshDates.ids[state.activeType] = DateTime.now().toMillis()
        })
      }
      finally {
        this.$patch({ isLoading: false })
      }
    },
    async checkIdsData(activeType?: iUdTypes) {
      if (activeType)
        this.$patch({ activeType })
      if (!this.hasIdsData())
        await this.fetchIdsData()
    },
    hasIdsData() {
      const hasIdsData = this.getUdIds.size > 0
      const lastDate = this.refreshDates.ids[this.activeType]
      const lastDateIsValid = lastDate ? DateTime.fromMillis(lastDate).diffNow().as('hours') < 6 : false
      return hasIdsData && lastDateIsValid
    },
    async parseUdRosterData(data: unknown[]) {
      const parsedData = await parseUdPicks(data)
      usePlayerDataStore().patchPlayerMetrics(parsedData as unknown as Array<iPlayer>, ['id_sleeper', 'id_pff', 'id_pos', 'id_team'], 'id_ud')
      const rosterData = parseUdRosterPlayers(parsedData)
      await this.checkAdpData()
      await this.addUdPlayerMetrics(rosterData)
      patchUdAdpMetrics(rosterData)
      const rosterAdp = mergeUdRostershipAdpData(rosterData, this.getUdAdp).sort(sortByAttribute('ud_adp'))
      this.$patch((state) => {
        state.rostershipData[state.activeType] = rosterAdp
        state.rostershipPickData[state.activeType] = parsedData
      })
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useUdStore, import.meta.hot))
